.root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  display: flex;
}

.content {
  position: relative;
  display: flex;
  overflow: auto;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  z-index: 2;

  margin-top: 64px;
}

.content.static-toolbar {
  margin-top: 0;
}

.layout-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  min-width: 320px;
}
